<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-office-building-marker
      </v-icon> My Sites
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#37474F"
          @click="$router.push({ name: 'Group Locations' })"
        >
          Group Site
        </v-btn>
        <v-btn
          color="#FF3700"
          @click="$router.push({ name: 'Add Locations' })"
        >
          Add Site
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="locationsList"
        :search="search"
        class="elevation-1"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.address.city }}</td>
            <td>{{ item.address.state }}</td>
            <td>{{ item.address.country }}</td>
            <td>{{ item.timezone | timeZoneByName }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="showSiteCheckIns(item.id)"
              >
                <v-icon>mdi-map-marker-account</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="downloadQrCode(item.id, item.name)"
              >
                <v-icon>mdi-qrcode</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import QRCode from 'qrcode';
  import { sciAppDomainUrl } from 'src/app-globals';

  export default {
    name: 'Locations',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      timeZoneByName (val) {
       return val.slice(val.indexOf(' ') + 1);
      },
    },
    data () {
      return {
        showForm: false,
        showDelete: false,
        deleteId: '',
        headers: [
          { text: 'Site Name', align: 'start', value: 'name' },
          { text: 'City', value: 'city' },
          { text: 'State', value: 'state' },
          { text: 'Country', value: 'country' },
          { text: 'Time Zone', value: 'location_timezone' },
          { text: 'Created At', value: 'created_at' },
          { text: 'Check-Ins', value: 'check-ins' },
          { text: 'QR Code', value: 'qrcode' },
        ],
        loading: false,
        search: '',
      };
    },
    computed: {
      locationsList () {
        return this.$store.getters['locations/getLocationsList'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.fetchLocationsList();
      this.loading = false;
    },
    methods: {
      async fetchLocationsList () {
        this.loading = true;
        await this.$store.dispatch('locations/fetchLocationsList');
        this.loading = false;
      },
      deleteIssue () {
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to perform this action. Please contact your administrator.',
          type: Constants.ALERT_TYPE_INFO,
        });
      },
      showSiteCheckIns (locationId) {
        this.$router.push(`/locations/${locationId}/check-ins`);
      },
      async downloadQrCode (siteId, siteName) {
        this.loading = true;
        QRCode.toDataURL(`${sciAppDomainUrl}/sites/${siteId}`, { errorCorrectionLevel: 'H' })
        .then(url => {
          var a = document.createElement('a');
          a.href = url;
          a.download = `${siteName}.png`;
          a.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
